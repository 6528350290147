namespace gotoAndPlay {

    export interface ISearchBarSettings {
        OpenClass: string;
        OpenButtonSelector: string;
        CloseButtonSelector: string;
    }

    export const SearchBarSettings = {
        OpenClass: 'search-bar-is-open',
        OpenButtonSelector: '.js-open-button',
        CloseButtonSelector: '.js-close-button',
    };

    export class SearchBar {

        static initSelector: string = '.search-bar';

        element: JQuery;
        settings: ISearchBarSettings;
        openButton: JQuery;
        closeButton: JQuery;

        constructor(target: HTMLElement, settings?: ISearchBarSettings) {
            this.settings = jQuery.extend(SearchBarSettings, settings) as ISearchBarSettings;
            this.element = $(target);
            this.openButton = this.element.find(this.settings.OpenButtonSelector);
            this.closeButton = this.element.find(this.settings.CloseButtonSelector);
            this.openButton.on('click', this.openSearch.bind(this));
            this.closeButton.on('click', this.closeSearch.bind(this));
        }

        openSearch(): void {
            $('body').addClass(this.settings.OpenClass);
            this.element.find('.suggestion-field__input').trigger('focus');
        }

        closeSearch(): void {
            $('body').removeClass(this.settings.OpenClass);
        }
    }

    $(document).on('enhance.search-bar', function (event) {
        $(event.target).find(SearchBar.initSelector).addBack(SearchBar.initSelector).each((index: number, element: Document) => {
            if (element instanceof HTMLElement) {
                let field = $(element);
                if (!field.data('searchBarClass')) {
                    field.data('searchBarClass', new SearchBar(element));
                }
            }
        });
    });

    $(() => {
        $(document).trigger('enhance.search-bar');
    });
}
