// import '../../../node_modules/slick-carousel/slick/slick.min.js';
namespace gotoAndPlay {
    export class Stories {
        static initSelector: string = '.stories';
        static carouselSelector: string = '.stories__list';

        public element: JQuery;
        public input: JQuery;
        public label: JQuery;
        public settings: JQuerySlickOptions;

        private carousel: JQuery;
        private slick: JQuery;

        constructor(target: HTMLElement) {
            this.element  = $(target);
            this.carousel = this.element.find(Stories.carouselSelector);
            this.settings = {
                adaptiveHeight: false,
                arrows: false,
                autoplay: false,
                dots: true,
                dotsClass: 'stories__dots',
                infinite: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true,
                        },
                    },
                ],
                slidesToScroll: 2,
                swipe: true,
                variableWidth: true,
            };

            this.init();
        }

        init(): void {
            this.slick = this.carousel.slick(this.settings);
        }
    }

    $(document).on('enhance.stories', function(event) {
        $(event.target).find(Stories.initSelector).addBack(Stories.initSelector).each((index: number, element: Document) => {
            let storiesElement = $(element as any);
            if (!storiesElement.data('textfieldClass')) {
                storiesElement.data('textfieldClass', new Stories(element as any));
            }
        });
    });

    $(() => {
        $(document).trigger('enhance.stories');
    });
}
