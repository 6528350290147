namespace gotoAndPlay {

    export class Hospital {
        static initSelector: string = '.hospital';
        static carouselSelector: string = '.hospital__gallery';

        public element: JQuery;
        public input: JQuery;
        public label: JQuery;
        public settings: JQuerySlickOptions;

        private carousel: JQuery;
        private slick: JQuery;

        constructor(target: HTMLElement) {
            this.element  = $(target);
            this.carousel = this.element.find(Hospital.carouselSelector);
            this.settings = {
                adaptiveHeight: true,
                arrows: false,
                autoplay: false,
                centerMode: true,
                dots: false,
                infinite: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                variableWidth: true,
            };

            this.init();
        }

        init(): void {
            if (window.innerWidth < Helpers.bp.sm) {
                this.slick = this.carousel.slick(this.settings);
            }

            $(window).on('resize', () => {
                if (window.innerWidth < Helpers.bp.sm) {
                    this.slick = this.carousel.slick(this.settings);
                } else if (this.slick != undefined) {
                    this.slick.slick('unslick');
                }
            });
        }
    }

    $(document).on('enhance.hospital', function(event) {
        $(event.target).find(Hospital.initSelector).addBack(Hospital.initSelector).each((index: number, element: Document) => {
            let hospitalElement = $(element as any);
            if (!hospitalElement.data('textfieldClass')) {
                hospitalElement.data('textfieldClass', new Hospital(element as any));
            }
        });
    });

    $(() => {
        $(document).trigger('enhance.hospital');
    });
}
