namespace gotoAndPlay {

    export class Patient {

        constructor(target: HTMLElement) {
            this.init();
        }

        init(): void {
            let tag = this.getParameterByName('sid');
            if (tag != '') {
                $('html, body').animate({
                    scrollTop: $('#' + tag).offset().top,
                }, 100);
            }
        }

        getParameterByName(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            let results = regex.exec(location.search);

            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }
    }

    $(document).ready(function() {
        $('#patients').each(
            function() {
                let patient = new Patient(this);
            },
        );
    });
}
